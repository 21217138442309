:root {
  
}


/* Added body margin 0 */
body{
  margin: 0;
  padding: 0;
}

.App {
  background: linear-gradient(106.37deg,
      #ffe1bc 29.63%,
      #ffcfd1 51.55%,
      #f3c6f1 90.85%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  transition: all .3s;

  --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
  --pink: rgb(255, 145, 157);
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #f799a354;
  --hardBackground: white;
  --font-color: black;
  --minor-detail: rgb(80,80,80);
  --sidebar-back: rgb(255, 209, 216);
}

.App-dark {
  background: linear-gradient(106.37deg,
      #4356a3 29.63%,
      #3e2da0 51.55%,
      #6732a0 90.85%);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  transition: all .3s;

  --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
  --orange: #1ffc4f;
  --black: #242d49;
  --gray: #788097;
  --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
  --pink: rgb(255, 145, 157);
  --glass: rgba(255, 255, 255, 0.54);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
  --activeItem: #a47dca54;
  --hardBackground: rgba(0, 0, 0, 0.656);
  --font-color: white;
  --minor-detail: rgb(217, 216, 216);
  --sidebar-back: rgb(123, 157, 250);

  color: white;
}

.AppGlass {
  display: grid;
  grid-template-columns: 11rem auto 20rem;
  height: 97%;
  width: 97%;
  border-radius: 2rem;
  background-color: var(--glass);
  overflow: hidden;
}

/* TODO: This is not working properly. Check grid-template. Last column is too big */
@media screen and (max-width: 1200px){
  .AppGlass{
    grid-template-columns: 10% 50% auto;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 768px){
  .AppGlass{
    grid-template-columns: 1fr;
    
  }
  .AppGlass::-webkit-scrollbar{
    display: none;
  }
}