$logo-height: 3rem;
$selector-height: 2rem;

.sidebar{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
}

.bars{
    display: none;
}

.logo{
    height: 4%;
    margin: 3rem 0 4rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    img{
        height: $logo-height;
        width: $logo-height;
    }   
}

.logo-text{
    font-weight: 700;
    font-size: $logo-height * 0.45;
    margin-left: 1rem;
}

.logo-text-color{
    color: var(--pink);
}

.menu-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    height: 2.5rem;
    position: relative;
    font-size: 14px;
    margin-left: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: background, border, margin 300ms ease;
    &:last-child{
        position: absolute;
        bottom: 2.3rem;
        width: 100%;
    }
}

.selector{
    background: var(--activeItem);
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    margin-left: 0;
    &::before{
        content: '';
        width: 8px;
        margin-right: calc(1rem - 8px);
        height: 100%;
        background: var(--pink);
    }
}

@media screen and (max-width: 1200px){
    .menu-item>span{
        display: none;
    }
    .menu-item{
        margin-top: 3rem;
    }
    .logo{
        display: none;
    }
}


@media screen and (max-width: 768px){
    .sidebar{
        position: fixed;
        z-index: 9;
        background: var(--sidebar-back);
        width: 55%;
        padding-right: 1rem;
        height: 100%;
    }
    .menu-item>span{
        display: block;
    }

    .logo{
        display: block;
    }

    .menu-item:last-child{
        position: relative;
        margin-top: 5rem; 
    }
    .bars{
        display: flex;
        position: fixed;
        top: 2rem;
        z-index: 9;
        background: var(--sidebar-back);
        padding: 10px;
        border-radius: 10px;
    }
}