.updates{
    background: var(--hardBackground);
    padding: 1rem;
    border-radius: 1rem;
    width: 85%;
    gap: 1rem;
    color: var(--font-color);
}

.update{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    font-size: 13px;
}

.update-img{
    height: 3rem;
}

.update-message{
    span:nth-child(1){
        font-weight: bold;
    }
    span:nth-child(3){
        color: var(--minor-detail);
    }
}

.update-date{
    line-height: 2rem;   
}

@media screen and (max-width: 760px) {
    .updates-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}