.main-dash{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1rem 1rem 0;
}

@media screen and (max-width: 1200px){
    .main-dash{
        justify-content: flex-start;
        margin-top: 2.5rem;
    }
}

@media screen and (max-width: 768px){
    .main-dash{
        align-items: center;
    }
}